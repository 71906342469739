import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/css/common.css'
import { Col, Row } from 'vant';
import { Button } from 'vant';
import 'vant/lib/index.css';


createApp(App)
.use(Button)
.use(Row)
.use(Col)
.use(store)
.use(router)
.mount('#app')
